.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: $default-avatar-size;
  width: $default-avatar-size;
  border-radius: 50%;

  .avatar-title {
    color: rgba(white, 0.8);
    background: white - 40;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: $default-font-size + 5;
  }

  & > a {
    width: 100%;
    height: 100%;
    display: block;
    transition: color 0.3s;
    color: $color-primary;
  }

  & > a > img,
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.avatar-sm {
    height: $default-avatar-size - 1;
    width: $default-avatar-size - 1;

    .avatar-title {
      font-size: $default-font-size;
    }

    &.avatar-state-primary,
    &.avatar-state-success,
    &.avatar-state-danger,
    &.avatar-state-warning,
    &.avatar-state-info,
    &.avatar-state-secondary,
    &.avatar-state-light,
    &.avatar-state-dark {
      &:before {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }

  &.avatar-lg {
    height: $default-avatar-size + 1.5;
    width: $default-avatar-size + 1.5;

    .avatar-title {
      font-size: $default-font-size + 15;
    }

    &.avatar-state-primary,
    &.avatar-state-success,
    &.avatar-state-danger,
    &.avatar-state-warning,
    &.avatar-state-info,
    &.avatar-state-secondary,
    &.avatar-state-light,
    &.avatar-state-dark {
      &:before {
        width: 1.2rem;
        height: 1.2rem;
        right: -1px;
      }
    }
  }

  &.avatar-xl {
    height: $default-avatar-size + 3.8;
    width: $default-avatar-size + 3.8;

    .avatar-title {
      font-size: $default-font-size + 25;
    }

    &.avatar-state-primary,
    &.avatar-state-success,
    &.avatar-state-danger,
    &.avatar-state-warning,
    &.avatar-state-info,
    &.avatar-state-secondary,
    &.avatar-state-light,
    &.avatar-state-dark {
      &:before {
        width: 1.2rem;
        height: 1.2rem;
        top: 2px;
        right: 7px;
      }
    }
  }

  &.avatar-state-primary,
  &.avatar-state-success,
  &.avatar-state-danger,
  &.avatar-state-warning,
  &.avatar-state-info,
  &.avatar-state-secondary,
  &.avatar-state-light,
  &.avatar-state-dark {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      top: -2px;
      right: -2px;
      border: 3px solid white;
    }
  }

  &.avatar-state-primary:before {
    background: $color-primary;
  }

  &.avatar-state-success:before {
    background: $color-success;
  }

  &.avatar-state-danger:before {
    background: $color-danger;
  }

  &.avatar-state-warning:before {
    background: $color-warning;
  }

  &.avatar-state-info:before {
    background: $color-info;
  }

  &.avatar-state-secondary:before {
    background: $color-secondary;
  }

  &.avatar-state-light:before {
    background: $color-light;
  }

  &.avatar-state-dark:before {
    background: $color-dark;
  }
}

.avatar-group {
  display: inline-flex;
  margin-right: 2.2rem;

  figure.avatar {
    border: 2px solid white;
    margin-right: -1rem !important;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      position: relative;
      z-index: 1;
    }
  }
}
