@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import "Vars";
@import "Typography";
@import "Button";
@import "Modal";
@import "Tab";
@import "Color";
@import "Avatar";
@import "DropdownMenu";
@import "Membership";
@import "Navigation";
@import "Dark";
@import "Other";
@import "Rtl";

.layout {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;

  .content {
    display: flex;
    flex: 1;
    width: 100vw;

    .sidebar-group {
      display: flex;
      flex-direction: column;

      .sidebar {
        &:not(.active) {
          display: none;
        }

        .sidebar-title {
          font-size: 22px;
        }

        background: white;
        border-radius: 5px;
        overflow: hidden;
        width: 350px;
        display: flex;
        flex-direction: column;
        flex: 1;

        &.default-messages {
          width: 490px;

          & .messages {
            & .message-item {
              max-width: initial;
            }
          }
        }

        & > header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 30px;
          height: 80px;
          font-weight: 600;
          border-radius: 0;

          ul {
            margin-bottom: 0;

            li {
              margin-bottom: 0;
            }

            a {
            }
          }
        }

        & > form {
          padding: 17px 14px 17px 30px;
          margin-bottom: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .form-control {
            border-radius: 5px;
            height: auto;
            border: 1px solid $style-color;
            padding: 10px 15px;
          }
        }

        & .sidebar-body {
          flex: 1;
          overflow: auto;
        }

        .list-group-item {
          padding: 17px 30px;
          display: flex;
          cursor: pointer;

          &.open-chat {
            background-color: $style-color + 10;

            .users-list-body {
              p {
                color: black + 100;
              }
            }
          }

          figure {
            margin-right: 1rem;
          }

          .users-list-body {
            flex: 1;
            position: relative;
            min-width: 0px;
            display: flex;

            &__inner-wrap {
              display: flex;
              align-items: center;
            }

            & > div:first-child {
              min-width: 0;
              display: flex;
              flex-direction: column;
              flex: 1;
            }

            h5 {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: $default-font-size + 2;
              font-weight: 500;
              margin-bottom: 0.2rem;
            }

            p {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-bottom: 0;
              color: black + 150;

              &.padding-right {
                padding-right: 70px;
              }
            }

            .users-list-action {
              right: 0;
              position: absolute;

              [data-toggle="dropdown"] {
                i {
                  font-size: $default-font-size + 4;
                }
              }

              .new-message-count {
                width: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 0;
                font-size: $default-font-size - 1;
                height: 23px;
                background-color: $color-primary;
                color: white;
                border-radius: 50%;
                margin-left: auto;
              }

              .action-toggle {
                display: none;
                text-align: right;
                position: absolute;
                right: 0;

                .dropdown {
                  & > span {
                    color: $color-primary;
                  }
                }
              }
            }
          }

          &:hover {
            .users-list-action {
              .new-message-count {
                display: none;
              }

              .action-toggle {
                display: block;
              }
            }
          }
        }
      }
    }

    .chat {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-right: 1px solid $style-color;
      border-left: 1px solid $style-color;
      overflow: hidden;

      .chat-header {
        border-bottom: 1px solid $style-color;
        padding: 17px 30px;

        &__inner-wrap {
          display: flex;
          justify-content: space-between;
        }

        &__description {
          margin-bottom: 0;
          padding-top: 14px;

          & span {
            font-weight: bold;
          }
        }

        .chat-header-user {
          display: flex;
          align-items: center;

          figure {
            margin-right: 1rem;
          }

          h5 {
            font-size: $default-font-size + 2;
            font-weight: 600;
            margin-bottom: 0;
            line-height: 1;
          }
        }

        .chat-header-action {
          ul {
            margin-bottom: 0;

            & > li {
              & > a {
                font-size: $default-font-size + 2;
              }
            }
          }
        }

        &__inner-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .chat-body {
        flex: 1;
        padding: 30px;

        &:not(.no-message) {
          overflow: hidden;
        }

        &.no-message {
          user-select: none;

          .no-message-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            text-align: center;

            svg {
              width: 50px;
              height: 50px;
              stroke: $color-primary;
              fill: $style-color;
            }

            p {
              font-size: $default-font-size + 2;
              color: black + 130;
            }
          }
        }

        .messages {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .message-item {
            max-width: 75%;
            margin-top: 3rem;
            display: flex;
            flex-direction: column;

            &:last-child {
              margin-bottom: 0;
            }

            .message-avatar {
              display: flex;
              align-items: center;
              margin-bottom: 1rem;

              .avatar {
                margin-right: 1rem;
              }

              h5 {
                font-size: 15px;
                margin-bottom: 0;
              }

              .time {
                color: #828282;
                margin-top: 2px;
                font-style: italic;
                font-size: 12px;

                .ti-double-check {
                  margin-left: 5px;

                  &:before,
                  &:after {
                    content: "✔";
                  }

                  &:after {
                    margin-left: -2px;
                  }
                }
              }
            }

            .message-date {
              color: #000;
            }

            .message-content {
              background: $style-color + 5;
              border-radius: 7px;
              padding: 10px 20px;
              white-space: pre-line;

              a {
                text-decoration: underline !important;
              }

              &.message-file {
                display: flex;
                word-break: break-word;

                .file-icon {
                  font-size: $default-font-size + 10;
                  margin-right: 1rem;
                  color: black + 130;
                }

                ul {
                  margin-top: 3px;
                  margin-bottom: 0;

                  li.list-inline-item {
                    margin-left: 0;
                    margin-right: 0.5rem;
                  }
                }
              }

              &.message-image {
                .image-wrap {
                  max-width: 300px;
                  cursor: pointer;
                  img {
                    width: 100%;
                  }
                }
              }
            }

            &.outgoing-message {
              align-items: flex-end;
              align-self: flex-end;

              .message-avatar {
                justify-content: flex-end;
              }

              .message-content {
                background-color: $color-primary;
                color: white;
                overflow-wrap: anywhere;

                a {
                  color: #fff;
                }

                .text-muted {
                  color: rgb(232, 230, 227) !important;
                }

                &.message-file {
                  .file-icon {
                    color: rgb(232, 230, 227);
                  }

                  li.list-inline-item a {
                    color: rgb(232, 230, 227);

                    &:hover {
                      opacity: 0.8;
                    }
                  }
                }
              }
            }

            &.outgoing-audio-message {
              margin-left: auto;

              .message-avatar {
                justify-content: flex-end;
              }

              .message-content {
                background-color: $color-primary;
                color: white;
              }
            }

            &.outgoing-video-message {
              margin-left: auto;

              .message-avatar {
                justify-content: flex-end;
              }

              .message-content {
                background-color: $color-primary;
                color: white;
              }
            }

            &.outgoing-file-message {
              margin-left: auto;

              .message-avatar {
                justify-content: flex-end;
              }

              .message-content {
                background-color: $color-primary;
                color: white;
              }

              .list-inline-item a {
                color: rgb(232, 230, 227);
              }

              .text-muted {
                color: rgb(232, 230, 227) !important;
              }

              .file-icon .fa {
                color: rgb(232, 230, 227);
              }
            }

            &.incoming-message {
              align-items: flex-start;
            }

            &.sameUser-1 {
              margin-top: 0.5rem !important;
            }
          }

          .messages-divider {
            width: 100%;
            max-width: 100%;
            position: relative;

            &:before {
              content: attr(data-label);
              display: block;
              position: absolute;
              top: -8px;
              letter-spacing: 0.5px;
              font-size: 11px;
              padding: 2px 8px;
              border-radius: 3px;
              background-color: $style-color;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }

      .chat-footer {
        border-top: 1px solid $style-color;
        padding: 17px 30px;

        form {
          display: flex;
          align-items: center;

          .form-buttons {
            display: flex;

            .btn {
              margin-left: 1rem;
            }
          }
        }
      }

      & + .sidebar-group {
        margin-right: 0px;

        .sidebar {
          margin-right: 0px;
        }
      }
    }
  }
}

.input-error {
  border-color: $color-danger;
}

.tooltip {
  .arrow {
    display: none;
  }

  .tooltip-inner {
    background-color: rgba(black, 0.5);
  }
}

.kVEayH {
  background: $color-primary !important;
  padding: 0;
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  box-shadow: none !important;
}

.fKLrJo {
  background: $color-primary !important;
}

.call {
  text-align: center;

  .modal-content {
    .modal-body {
      padding: 50px 0;
    }
  }

  .action-button {
    margin-top: 3rem;

    .btn {
      width: 70px;
      height: 70px;
      margin: 0 15px;

      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
}

.list-inline__filter-button {
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 14px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.group-type__label {
  padding: 2px 14px;
  border-radius: 4px;
  height: 26px;
  margin-left: 20px;
  color: #fff;

  & .group-type__text {
    color: #fff !important;
  }
}

@import "Responsive";
