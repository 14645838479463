.bg-primary {
  background: $color-primary !important;
  color: white !important;
}

.bg-primary-bright {
  background: $color-primary-bright !important;
}

.bg-primary-gradient {
  background: linear-gradient(230deg, $color-primary, $color-primary + 40) !important;
  color: white !important;
}

.bg-info {
  background: $color-info !important;
  color: white !important;
}

.bg-info-bright {
  background: $color-info-bright !important;
}

.bg-info-gradient {
  background: linear-gradient(230deg, $color-info, $color-info + 40) !important;
  color: white !important;
}

.bg-secondary {
  background: $color-secondary !important;
  color: white !important;
}

.bg-secondary-bright {
  background: $color-secondary-bright !important;
}

.bg-secondary-gradient {
  background: linear-gradient(230deg, $color-secondary, $color-secondary + 40) !important;
  color: white !important;
}

.bg-success {
  background: $color-success !important;
  color: white !important;
}

.bg-success-bright {
  background: $color-success-bright !important;
}

.bg-success-gradient {
  background: linear-gradient(230deg, $color-success, $color-success + 40) !important;
  color: white !important;
}

.bg-danger {
  background: $color-danger !important;
  color: white !important;
}

.bg-danger-bright {
  background: $color-danger-bright !important;
}

.bg-danger-gradient {
  background: linear-gradient(230deg, $color-danger, $color-danger + 40) !important;
  color: white !important;
}

.bg-danger-light {
  background: $color-danger + 150 !important;
}

.bg-warning {
  background: $color-warning !important;
  color: white !important;
}

.bg-warning-bright {
  background: $color-warning-bright !important;
}

.bg-warning-gradient {
  background: linear-gradient(230deg, $color-warning, $color-warning + 40) !important;
  color: white !important;
}

.bg-light {
  background: $color-light !important;
}

.bg-dark {
  background: $color-dark !important;
  color: white !important;
}

.bg-dark-bright {
  background: $color-dark-bright !important;
}

.bg-dark-gradient {
  background: linear-gradient(230deg, $color-dark, $color-dark + 30) !important;
  color: white !important;
}

.bg-facebook {
  background: $color-facebook !important;
  color: white !important;
}

.bg-twitter {
  background: $color-twitter !important;
  color: white !important;
}

.bg-linkedin {
  background: $color-linkedin !important;
  color: white !important;
}

.bg-whatsapp {
  background: $color-whatsapp !important;
  color: white !important;
}

.bg-instagram {
  background: $color-instagram !important;
  color: white !important;
}

.bg-dribbble {
  background: $color-dribbble !important;
  color: white !important;
}

.bg-google {
  background: $color-google !important;
  color: white !important;
}

.bg-youtube {
  background: $color-youtube !important;
  color: white !important;
}

// texts

.text-primary {
  color: $color-primary !important;
}

.text-info {
  color: $color-info !important;
}

.text-success {
  color: $color-success !important;
}

.text-danger {
  color: $color-danger !important;
}

.text-warning {
  color: $color-warning !important;
}

.text-light {
  color: $color-light !important;
}

.text-facebook {
  color: $color-facebook !important;
}

.text-twitter {
  color: $color-twitter !important;
}

.text-google {
  color: $color-google !important;
}

.text-linkedin {
  color: $color-linkedin !important;
}

.text-instagram {
  color: $color-instagram !important;
}

.text-whatsapp {
  color: $color-whatsapp !important;
}

.text-dribbble {
  color: $color-dribbble !important;
}
